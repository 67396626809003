import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';

import mavenLogo from 'public/static/maven-logo.svg';

import { OrganizationInfoType } from 'reducers/organizationSlice';
import { RootState } from 'store';
import { useGetUserQuery, useLazyGetOrganizationQuery } from 'store/api';

import Form from 'components/form';

import Button from 'ui-kit/button';
import { ButtonType } from 'ui-kit/button/type';
import Input from 'ui-kit/input';
import { createToastError } from 'ui-kit/alert-info/controls';

import { Links } from 'types/links';

import { organizationLexics, schemaOrganization } from './constants';
import { lexics } from 'data/lexics';
import {
  LOGIN_BTN_TEXT,
  REGISTRATION_BTN_TEXT,
} from 'components/auth/constants';

import styles from './styles.module.scss';

const container = {
  hidden: { opacity: 0, y: '-50%' },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeIn',
      duration: 0.3,
    },
  },
  exit: {
    y: '-50%',
    opacity: 0,
    transition: {
      ease: 'easeOut',
      duration: 0.3,
    },
  },
};

export type FormValues = {
  organization: string;
};

const inputName = 'organization';

const OrganizationContent = () => {
  const { data: user } = useGetUserQuery();
  const [trigger, { data, isLoading }] = useLazyGetOrganizationQuery();
  const { name, registrationAllowed } = useSelector<
    RootState,
    OrganizationInfoType
  >(({ organization }) => organization);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [inputName]: name ?? '',
    },
    resolver: yupResolver(schemaOrganization),
  });
  const router = useRouter();

  const onSubmit = async ({ organization }: FormValues) => {
    if (isLoading) return;
    await trigger(organization)
      .unwrap()
      .catch((error) => {
        if (error.status === 402) {
          createToastError(
            `${lexics.api.response.organization.error} ${organization}`,
          );
        } else {
          setError(
            inputName,
            {
              type: 'server',
              message: organizationLexics.NOT_FOUND,
            },
            { shouldFocus: true },
          );
        }
      });
  };

  useEffect(() => {
    if (user) {
      router.replace(Links.home);
    }
  }, [user, router, trigger]);

  return (
    <div className={styles.background}>
      <div className={styles.wrapper}>
        <Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.img}>
            <Image src={mavenLogo} alt="Maven" unoptimized />
          </div>
          <h1 className={styles.text}>Добро пожаловать!</h1>
          {!data ? (
            <>
              <div className={styles.elevate}>
                <Controller
                  name={inputName}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Input
                      label="Название организации"
                      placeholder="Название организации"
                      autoComplete={inputName}
                      defaultValue={name ?? ''}
                      customMessage
                      fieldState={fieldState}
                      {...field}
                    />
                  )}
                />
                <AnimatePresence>
                  {errors.organization && (
                    <motion.div
                      layout="position"
                      className={styles.error}
                      variants={container}
                      initial="hidden"
                      animate="show"
                      exit="exit">
                      {errors.organization.message}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <Button
                loading={isLoading}
                className={styles.button}
                type={ButtonType.submit}>
                Войти
              </Button>
            </>
          ) : (
            <>
              <Button
                type={ButtonType.button}
                href={
                  router.query?.redirect
                    ? `${Links.login}?redirect=${router.query?.redirect}`
                    : Links.login
                }
                className={styles.buttonGroup}>
                {LOGIN_BTN_TEXT}
              </Button>
              {registrationAllowed ? (
                <Button
                  type={ButtonType.button}
                  href={Links.registration}
                  className={styles.buttonGroup}>
                  {REGISTRATION_BTN_TEXT}
                </Button>
              ) : null}
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default OrganizationContent;
