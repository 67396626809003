import * as yup from 'yup';
import { FormValues } from './organization-content';

// матчит на англ буквы, рус буквы (независимо от регистра), числа от 0 до 9, точка, пробел и хештег в любой комбинации и последовательности
export const ORGANIZATION_REGEX = /^[A-zА-ЯЁа-яё\d\.\s\#]+$/;

export const organizationLexics = {
  NOT_FOUND: 'Организация не найдена',
  NOT_ALLOWED: 'Доступ заблокирован',
  REQUIRED: 'Введите название организации',
  INVALID: 'Название может содержать только буквы, цифры, точки',
};

export const schemaOrganization: yup.SchemaOf<FormValues> = yup.object().shape({
  organization: yup
    .string()
    .trim()
    .matches(ORGANIZATION_REGEX, {
      excludeEmptyString: true,
      message: organizationLexics.INVALID,
    })
    .required(organizationLexics.REQUIRED),
});
